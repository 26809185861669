export const environment = {
    production: true,
    clientUrl: 'https://etichette.muccigiovanni.it/',
    serverUrl: 'resource',
    descriptionSentence: 'Descrizione',
    ingredientSentence: 'Ingredienti',
    energeticValueSentence: 'Energia',
    fatSentence: 'Grassi',
    ofWhichSatFatsSentence: 'di cui Acidi Grassi Saturi',
    carboSentence: 'Carboidrati',
    ofWhichSugarsSentence: 'di cui Zuccheri',
    proteinsSentence: 'Proteine',
    transFatSentence: 'Acidi Grassi Trans',
    saltSentence: 'Sale',
    fiberSentence: 'Fibre',
    vitaminASentence: 'Vitamina A',
    vitaminCSentence: 'Vitamina C',
    calciumSentence: 'Calcio',
    ironSentence: 'Ferro',
    cholesterolSentence: 'Colesterolo',
    andSentence: 'e',
    traceOfSentence: 'Può contenere tracce di',
    nutritionalValuesFor100Sentence: 'Valori nutrizionali medi per 100g di prodotto:',
    withoutGlutensSentence: 'Senza glutine',
    cacaoMinSentence: 'cacao min.',
};

