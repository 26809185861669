import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper-layout',
  templateUrl: './wrapper-layout.component.html',
  styleUrls: ['./wrapper-layout.component.css']
})
export class WrapperLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
