import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LoginPayload, User} from '../../models/user';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const baseUrl = environment.serverUrl + '/api/auth';

@Injectable()
export class AuthService {

    private token: string;

    constructor(private httpClient: HttpClient) { }

    login(user: LoginPayload): Observable<string> {
        return this.httpClient.post<string>(`${baseUrl}/login`, user, {
            responseType: 'text' as any
        }).pipe(tap((token: string) => {
            if (token) {
                try {
                    this.saveToken(token);
                } catch (e) {
                }
            }
        }));
    }

    logout(): void {
        this.token = null;
        localStorage.removeItem(ACCESS_TOKEN);
    }

    getToken(): string {
        if (!this.token) {
            this.token = localStorage.getItem('ACCESS_TOKEN');
        }
        return this.token;
    }

    getUser(): Observable<User> {
        return this.httpClient.get<User>(`${baseUrl}/me`, { headers: this.authHeaders() });
    }

    authHeaders(): HttpHeaders {
        return new HttpHeaders({Authorization: localStorage.getItem('ACCESS_TOKEN')});
    }

    async isAuthenticated(): Promise<boolean> {
        try {
            const res = await this.httpClient.get(`${baseUrl}/me`, { headers: this.authHeaders() }).toPromise();
            return true;
        } catch (err) {
            return false;
        }
    }

    private saveToken(token: string): void {
        localStorage.setItem(ACCESS_TOKEN, token);
        this.token = token;
    }
}
