import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router
    ) {}

    async canActivate(): Promise<boolean> {
        if(await this.auth.isAuthenticated()) {
            return true;
        } else {
            this.router.navigateByUrl('auth');
            return false;
        }
    }
}
