<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">
        <img alt="Mucci" class="d-inline-block align-top" height="80" src="..\..\assets\mucci-logo.png" width="80">
    </a>
    <button aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
            data-target="#navbarNav" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">

            <li class="nav-item" [ngClass]="!!route?.children ? ' dropdown' : ''" *ngFor="let route of routes">
                <ng-container *ngIf="!!route?.children">
                    <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown"
                       href="#" id="navbarDropdownMenuLink">
                        {{route.title}}
                    </a>
                    <div *ngIf="!!route?.children" aria-labelledby="navbarDropdownMenuLink" class="dropdown-menu">
                        <a *ngFor="let child of route.children" [routerLinkActive]="['active']" class="dropdown-item dropdown-mucci" [routerLink]="child.url">{{child.title}}</a>
                    </div>
                </ng-container>
                <ng-container *ngIf="!route?.children">
                    <a [routerLinkActive]="['active']" class="nav-link collapsed" [routerLink]="route.url">
                        {{route.title}}
                    </a>
                </ng-container>
            </li>
            <li class="nav-item">
                <a (click)="logout()" class="nav-link collapsed">
                    <i class="fa fa-sign-out"></i> Esci
                </a>
            </li>
        </ul>
    </div>
</nav>

