import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { WrapperLayoutComponent } from './layouts/wrapper-layout/wrapper-layout.component';

@NgModule({
    declarations: [
        AppComponent,
        AuthLayoutComponent,
        WrapperLayoutComponent,
    ],
    imports: [
        SharedModule,
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        NgbModule,
        CommonModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        ToastrModule.forRoot()
    ],
    providers: [AuthService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
