import { AuthGuard } from './services/auth/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import {WrapperLayoutComponent} from './layouts/wrapper-layout/wrapper-layout.component';

const routes: Routes = [
    {path: 'auth', redirectTo: 'auth/login', pathMatch: 'full'},
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
        }]
    },
    {
        path: '',
        component: WrapperLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard]
            },
            {
                path: 'ingredients',
                loadChildren: () => import('./components/ingredient/ingredient.module').then(m => m.IngredientModule), canActivate: [AuthGuard]
            },
            {
                path: 'additives',
                loadChildren: () => import('./components/additive/additive.module').then(m => m.AdditiveModule), canActivate: [AuthGuard]
            },
            {
                path: 'categories',
                loadChildren: () => import('./components/category/category.module').then(m => m.CategoryModule), canActivate: [AuthGuard]
            },
            {
                path: 'labels',
                loadChildren: () => import('./components/label/labels.module').then(m => m.LabelModule), canActivate: [AuthGuard]
            },
            {
                path: 'sentences',
                loadChildren: () => import('./components/sentences/sentence.module').then(m => m.SentenceModule), canActivate: [AuthGuard]
            },
            {
                path: 'allergens',
                loadChildren: () => import('./components/allergens/allergens.module').then(m => m.AllergensModule), canActivate: [AuthGuard]
            },
            {
                path: 'mixed-products',
                loadChildren: () => import('./components/mix-products/mix-products.module').then(m => m.MixProductsModule), canActivate: [AuthGuard]
            },
            {
                path: 'single-products',
                loadChildren: () => import('./components/single-products/single-product.module').then(m => m.SingleProductModule), canActivate: [AuthGuard]
            }]
    },
    {
        path: '**',
        redirectTo: '',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
