import {NavbarComponent} from './navbar/navbar.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import {SortableHeaderDirective, TableComponent} from './table/table.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ModalDescriptionTranslationsComponent } from './modal-description-translations/modal-description-translations.component';
import {ModalTranslationsComponent} from './modal-translations/modal-translations.component';
import {OrderModule} from 'ngx-order-pipe';


@NgModule({
    declarations: [
        NavbarComponent,
        FooterComponent,
        TableComponent,
        SortableHeaderDirective,
        ModalTranslationsComponent,
        ModalDescriptionTranslationsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgbPaginationModule,
        ReactiveFormsModule,
        OrderModule,
        FormsModule
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        TableComponent,
        ModalTranslationsComponent,
        ModalDescriptionTranslationsComponent
    ]
})
export class SharedModule {
}
