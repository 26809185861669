import { AuthService } from 'src/app/services/auth/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

    constructor(
        private router: Router,
        private auth: AuthService
    ) {
    }

    routes = [
        {
            url: 'ingredients',
            title: 'Ingredienti'
        },
        {
            url: 'categories',
            title: 'Categorie per additivi'
        },
        {
            url: 'additives',
            title: 'Additivi'
        },
        {
            title: 'Template',
            children: [
                {
                    url: 'labels/show',
                    title: 'Mostra'
                },
                {
                    url: 'labels/create',
                    title: 'Aggiungi'
                }
            ]
        },
        {
            title: 'Prodotti',
            children: [
                {
                    url: 'single-products',
                    title: 'Singoli'
                },

                {
                    url: 'mixed-products',
                    title: 'Misti'
                }
            ]
        },
        {
            url: 'sentences',
            title: 'Frasi'
        },
        {
            url: 'allergens',
            title: 'Allergeni'
        }
    ];


    async logout(): Promise<void> {
        this.auth.logout();
        await this.router.navigateByUrl('auth');
    }

}
